import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import axios for making HTTP requests

const AdminLoginPage = ({ setIsAuthenticated }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Session timeout (10 minutes)
  const sessionTimeout = 10 * 60 * 1000; // 10 minutes in milliseconds

  useEffect(() => {
    const handleLogout = () => {
      setIsAuthenticated(false);
      localStorage.removeItem('currentUser');
      navigate('/admin-login');
    };

    let logoutTimer;

    const resetTimer = () => {
      clearTimeout(logoutTimer);
      logoutTimer = setTimeout(handleLogout, sessionTimeout);
    };

    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keydown', resetTimer);
    resetTimer(); // Start the timer on page load

    return () => {
      clearTimeout(logoutTimer);
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keydown', resetTimer);
    };
  }, [navigate, setIsAuthenticated, sessionTimeout]); // Added sessionTimeout to the dependency array

  const handleLogin = async () => {
    try {
      const response = await axios.post('/api/xapi', {
        action: 'login',
        email: email,
        password: password,
        isAdmin: true,  // Set this to true for admin login
      });

      if (response.data.message === 'Login successful') {
        setIsAuthenticated(true);
        localStorage.setItem('currentUser', email); // Store user info in local storage
        navigate('/admin');  // Redirect to the admin page after successful login
      } else if (response.data.message === 'Error 789: Unauthorized access') {
        setError('Error 789: Unauthorized access');
      } else {
        setError('Invalid credentials');
      }
    } catch (error) {
      console.error('Login failed:', error.response ? error.response.data.message : error.message);
      setError('Login failed. Please try again.');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-10 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-3xl font-bold text-center mb-8 text-brandBlue">Admin Login</h2>
        {error && <p className="text-red-500 text-center mb-4">{error}</p>}
        <input 
          type="text" 
          placeholder="Username or Email" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
          className="w-full px-4 py-2 mb-4 border border-gray-300 rounded-md text-lg"
        />
        <input 
          type="password" 
          placeholder="Password" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
          className="w-full px-4 py-2 mb-4 border border-gray-300 rounded-md text-lg"
        />
        <button 
          onClick={handleLogin} 
          className="w-full bg-brandBlue text-white py-3 rounded-md text-lg font-semibold hover:bg-blue-600"
        >
          Login
        </button>
      </div>
    </div>
  );
};

export default AdminLoginPage;
