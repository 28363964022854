import React, { useState } from 'react';
import axios from 'axios';

const ResetPasswordPage = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    // Utility function to validate email format
    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    // Utility function to validate password strength
    const validatePassword = (password) => {
        const minLength = 8;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumbers = /[0-9]/.test(password);
        const hasNonalphas = /[\W]/.test(password);
        return (
            password.length >= minLength &&
            hasUpperCase &&
            hasLowerCase &&
            hasNumbers &&
            hasNonalphas
        );
    };

    // Handle form submission for password reset
    const handlePasswordReset = async (event) => {
        event.preventDefault();
        setLoading(true);

        const token = new URLSearchParams(window.location.search).get('token');
        const email = new URLSearchParams(window.location.search).get('email');

        if (!token || !email) {
            setError('Invalid or missing token.');
            setLoading(false);
            return;
        }

        if (!validateEmail(email)) {
            setError('Invalid email format.');
            setLoading(false);
            return;
        }

        if (password !== confirmPassword) {
            setError('Passwords do not match!');
            setLoading(false);
            return;
        }

        if (!validatePassword(password)) {
            setError(
                'Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character.'
            );
            setLoading(false);
            return;
        }

        try {
            const response = await axios.post('/api/xapi', {
                action: 'resetPassword',
                email,
                password,
                token,
            });

            if (response.data.message === 'Password reset successful') {
                setMessage('Password reset successful! You can now log in with your new password.');
                setError('');
            } else if (response.data.message === 'Token expired') {
                setError('The reset token has expired. Please request a new password reset.');
            } else {
                setError('Failed to reset password. Please try again.');
            }
        } catch (error) {
            console.error('Error resetting password:', error);
            setError('Error resetting password. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#f4f4f4' }}>
            <div style={{ width: '400px', padding: '30px', backgroundColor: 'white', borderRadius: '8px', boxShadow: '0 0 10px rgba(0,0,0,0.1)' }}>
                <div style={{ textAlign: 'center' }}>
                    <img src="https://onboarding.affordablecleaningtoday.com/static/media/actlogo.4a49e55c9cccb7c71a5c.png" alt="ACT Logo" style={{ width: '100px', marginBottom: '20px' }} />
                    <h2 style={{ marginBottom: '20px', color: '#333' }}>Reset Your Password</h2>
                </div>
                <form onSubmit={handlePasswordReset}>
                    <div style={{ marginBottom: '15px' }}>
                        <input
                            type="password"
                            placeholder="New Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
                        />
                    </div>
                    <div style={{ marginBottom: '15px' }}>
                        <input
                            type="password"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                            style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
                        />
                    </div>
                    <button 
                        type="submit" 
                        disabled={loading}
                        style={{ 
                            width: '100%', 
                            padding: '10px', 
                            backgroundColor: loading ? '#ccc' : '#007bff', 
                            color: '#fff', 
                            borderRadius: '5px', 
                            border: 'none', 
                            cursor: loading ? 'not-allowed' : 'pointer' 
                        }}
                    >
                        {loading ? 'Processing...' : 'Reset Password'}
                    </button>
                </form>
                {message && <p style={{ marginTop: '20px', color: 'green', textAlign: 'center' }}>{message}</p>}
                {error && <p style={{ marginTop: '20px', color: 'red', textAlign: 'center' }}>{error}</p>}
            </div>
        </div>
    );
};

export default ResetPasswordPage;
