import React, { useState, useEffect } from 'react';
import Header from './Header';
import axios from 'axios';

const AdminPage = () => {
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({ email: '', password: '' });
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.post('/api/xapi', {
        action: 'getAllUsers'
      });
      setUsers(response.data.users || []); // Ensure users is an array even if empty
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Unable to fetch users. Please try again later.');
    }
  };

  const createUser = async () => {
    try {
      await axios.post('/api/xapi', {
        action: 'createUser',
        email: newUser.email,
        password: newUser.password,
      });
      setNewUser({ email: '', password: '' });
      fetchUsers(); // Refresh the list after creation
    } catch (error) {
      console.error('Error creating user:', error);
      setError('Unable to create user. Please try again later.');
    }
  };

  const deleteUser = async (email) => {
    try {
      await axios.post('/api/xapi', {
        action: 'deleteUser',
        email: email,
      });
      fetchUsers(); // Refresh the list after deletion
    } catch (error) {
      console.error('Error deleting user:', error);
      setError('Unable to delete user. Please try again later.');
    }
  };

  const resetUserTasks = async (email) => {
    try {
      await axios.post('/api/xapi', {
        action: 'resetTasks',
        email: email,
      });
      fetchUsers(); // Refresh the list after resetting tasks
    } catch (error) {
      console.error('Error resetting tasks:', error);
      setError('Unable to reset tasks. Please try again later.');
    }
  };

  return (
    <>
      <Header />
      <div className="min-h-screen bg-gray-100 p-8">
        <div className="max-w-4xl mx-auto bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-3xl font-bold mb-4 text-brandBlue">Admin Dashboard</h2>
          <button 
            onClick={() => { 
              localStorage.removeItem('currentUser'); 
              window.location.href = '/admin-login'; 
            }} 
            className="bg-red-500 text-white py-2 px-4 rounded-md mb-6"
          >
            Logout
          </button>
          {error && (
            <div className="bg-red-100 text-red-700 p-4 rounded-md mb-4">
              {error}
            </div>
          )}
          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-2">Create New User</h3>
            <input
              type="email"
              placeholder="Email"
              value={newUser.email}
              onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
              className="w-full px-4 py-2 mb-4 border border-gray-300 rounded-md"
            />
            <input
              type="password"
              placeholder="Password"
              value={newUser.password}
              onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
              className="w-full px-4 py-2 mb-4 border border-gray-300 rounded-md"
            />
            <button
              onClick={createUser}
              className="w-full bg-brandBlue text-white py-3 rounded-md font-semibold hover:bg-blue-600"
            >
              Create User
            </button>
          </div>
          <h3 className="text-xl font-semibold mb-4">Manage Users</h3>
          {users && users.length > 0 ? (
            <ul>
              {users.map(user => (
                <li key={user.email} className="mb-4 p-4 border border-gray-300 rounded-md">
                  <div className="flex justify-between items-center mb-2">
                    <div>
                      <span className="font-bold text-lg">{user.email}</span>
                      <span className="text-sm text-gray-500 ml-2">({user.role})</span>
                      <div className="text-sm text-gray-500">Progress: {user.progress}%</div>
                      <div className="text-sm text-gray-500">Last Login: {new Date(user.lastLogin).toLocaleString()}</div>
                      <div className="text-sm text-gray-500">Login Count: {user.loginCount}</div>
                      <div className="text-sm text-gray-500">Total Time Spent: {Math.floor(user.totalTimeSpent / 60)} minutes</div>
                    </div>
                    <div>
                      <button
                        onClick={() => resetUserTasks(user.email)}
                        className="bg-yellow-500 text-white py-1 px-3 rounded-md mr-2"
                      >
                        Reset Tasks
                      </button>
                      <button
                        onClick={() => deleteUser(user.email)}
                        className="bg-red-500 text-white py-1 px-3 rounded-md"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                  <div className="mt-4">
                    <h4 className="font-semibold">Tasks</h4>
                    <ul className="mt-2">
                      {user.tasks && user.tasks.length > 0 ? (
                        user.tasks.map((task, index) => (
                          <li key={index} className="flex justify-between items-center mb-2">
                            <div>
                              <span className="font-semibold">{task.taskName}</span>
                              <span className={`ml-2 text-sm ${task.completed ? 'text-green-500' : 'text-red-500'}`}>
                                {task.completed ? 'Completed' : 'Incomplete'}
                              </span>
                            </div>
                            <div className="text-sm text-gray-500">
                              {task.timestamp ? `Completed on: ${new Date(task.timestamp).toLocaleString()}` : 'Not yet completed'}
                            </div>
                          </li>
                        ))
                      ) : (
                        <li className="text-gray-500">No tasks found for this user.</li>
                      )}
                    </ul>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p>No users found.</p>
          )}
        </div>
      </div>
    </>
  );
};

export default AdminPage;
