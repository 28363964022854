import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import axios from 'axios'; // Import axios for making HTTP requests
import LoginPage from './components/LoginPage';
import OnboardingPage from './components/OnboardingPage';
import AdminLoginPage from './components/AdminLoginPage';
import AdminPage from './components/AdminPage';
import ResetPasswordPage from './components/ResetPasswordPage'; // Import the ResetPasswordPage
import GlobalStyles from './styles/GlobalStyles';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Check authentication status when the app loads
    const checkAuth = async () => {
      try {
        const response = await axios.get('/api/xapi');  // Endpoint to verify if the user is authenticated
        setIsAuthenticated(response.data.isAuthenticated);
      } catch (error) {
        console.error('Failed to verify authentication status:', error);
        setIsAuthenticated(false);
      }
    };

    checkAuth();
  }, []);

  return (
    <Router>
      <GlobalStyles />
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/onboarding" element={<OnboardingPage />} />
        <Route path="/admin-login" element={<AdminLoginPage setIsAuthenticated={setIsAuthenticated} />} />
        
        {/* Protect the /admin route */}
        <Route path="/admin" element={isAuthenticated ? <AdminPage /> : <Navigate to="/admin-login" />} />

        {/* Add the Reset Password route */}
        <Route path="/reset-password" element={<ResetPasswordPage />} />

        <Route path="/" element={<LoginPage />} />
        {/* Redirect all undefined routes to login */}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
}

export default App;
