import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Removed 'Link' since it's not used
import axios from 'axios';
import actLogo from '../assets/actlogo.png';
import backgroundImage from '../assets/back1.png'; // Import the background image

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [resetMessage, setResetMessage] = useState(''); // Add state for reset password message
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
        const response = await axios.post('/api/xapi', {
            action: 'login',
            email: email,
            password: password,
            isAdmin: false,  // Explicitly set this to false or omit it
        });
  
        if (response.data.message === 'Login successful') {
            localStorage.setItem('currentUser', email); // Save the email to local storage
            if (email === 'admin@example.com') {
                navigate('/onboarding'); // Allow admin to log into onboarding for testing
            } else {
                navigate('/onboarding'); // Redirect other users to onboarding page
            }
        } else {
            setError('Invalid credentials');
        }
    } catch (error) {
        console.error('Login failed:', error.response ? error.response.data.message : error.message);
        setError('Login failed. Please try again.');
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      setResetMessage('Please enter your email first.');
      return;
    }
    
    try {
        await axios.post('/api/xapi', {
            action: 'requestPasswordReset',
            email: email,
        });
        setResetMessage('If your email is in the system, you will receive a password reset link.');
    } catch (error) {
        console.error('Failed to send reset email:', error);
        setResetMessage('Failed to send reset email. Please try again.');
    }
  };

  return (
    <div 
      className="flex items-center justify-center min-h-screen bg-cover bg-center"
      style={{ backgroundImage: `url(${backgroundImage})` }} // Apply the background image
    >
      <div className="bg-white p-10 rounded-lg shadow-lg w-full max-w-md">
        <img src={actLogo} alt="ACT Logo" className="mx-auto mb-6 w-32" />
        <h2 className="text-3xl font-bold text-center mb-8 text-brandBlue">
          Affordable Cleaning Today Onboarder 2.0
        </h2>
        {error && <p className="text-red-500 text-center mb-4">{error}</p>}
        <input 
          type="email" 
          placeholder="Email" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
          className="w-full px-4 py-2 mb-4 border border-gray-300 rounded-md text-lg"
        />
        <input 
          type="password" 
          placeholder="Password" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
          className="w-full px-4 py-2 mb-4 border border-gray-300 rounded-md text-lg"
        />
        <button 
          onClick={handleLogin} 
          className="w-full bg-brandBlue text-white py-3 rounded-md text-lg font-semibold hover:bg-blue-600"
        >
          Login
        </button>
        {/* Forgot Password Link */}
        <div className="text-center mt-4">
          <button onClick={handleForgotPassword} className="text-brandBlue hover:underline">
            Forgot your password?
          </button>
        </div>
        {resetMessage && <p className="text-center text-green-500 mt-4">{resetMessage}</p>}
      </div>
    </div>
  );
};

export default LoginPage;
