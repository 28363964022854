import React, { useState, useEffect } from 'react';
import Header from './Header';
import axios from 'axios';
import '../index.css';

const OnboardingPage = () => {
  const [completedTasks, setCompletedTasks] = useState([]);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const userEmail = localStorage.getItem('currentUser');

  useEffect(() => {
    if (!userEmail) {
      console.error("User not authenticated");
      window.location.href = '/login'; // Redirect to login if not authenticated
      return;
    }

    const fetchProgress = async () => {
      try {
        console.log(`Fetching progress for user: ${userEmail}`);
        const response = await axios.post('/api/xapi', {
          action: 'getUserProgress',
          email: userEmail,
        });

        const tasks = response.data.completedTasks || [];
        setCompletedTasks(tasks);

        const completedCount = tasks.filter(task => task.completed).length;
        setProgress((completedCount / tasks.length) * 100);

        console.log(`Fetched progress: ${completedCount} out of ${tasks.length} tasks completed.`);
      } catch (error) {
        console.error('Error fetching progress:', error);
        setError('Unable to fetch progress. Please try again later.');
      }
    };

    fetchProgress();
  }, [userEmail]);

  const handleCheck = async (taskNumber) => {
    try {
      console.log(`Updating task number: ${taskNumber} for user: ${userEmail}`);
      
      const updatedTasks = completedTasks.map((task, index) =>
        index === taskNumber ? { ...task, completed: true } : task
      );

      await axios.post('/api/xapi', {
        action: 'updateProgress',
        email: userEmail,
        taskNumber: taskNumber,
        response: 'Completed',
        timestamp: new Date(),
      }).then(() => {
        // Only update the state if the API call is successful
        setCompletedTasks(updatedTasks);

        const completedCount = updatedTasks.filter(task => task.completed).length;
        setProgress((completedCount / updatedTasks.length) * 100);

        console.log(`Task ${taskNumber} updated successfully.`);
      }).catch(err => {
        console.error('Error updating progress:', err);
        setError('Unable to update task progress. Please try again later.');
      });

    } catch (error) {
      console.error('Unexpected error:', error);
      setError('Unable to update task progress. Please try again later.');
    }
  };

  const tasks = [
    {
      number: 0,
      title: "Learn Booking Koala",
      description: "Booking Koala is what we use to track jobs, designate jobs, etc. Please visit the website and get familiar with it.",
      link: "https://affordablecleaningtoday.com/booking-koala/",
      linkText: "Visit Booking Koala",
      actionText: "I have watched the video"
    },
    {
      number: 1,
      title: "Confirm if you got an email from Square Payroll",
      description: "This is where we pay you via direct deposit. We pay every 2 weeks, the 15th and the 30th",
      actionText: "I received the email and signed up"
    },
    {
      number: 2,
      title: "After you've logged into this system, 5 minutes later check if you recieved an email with the subject Welcome to Affordable Cleaning Today! you will promopted to reset your password to log in"	,
      link: "https://affordablecleaningtoday.bookingkoala.com/login",
      linkText: "Log into Booking Koala",
      actionText: "Done"
    },
    {
      number: 3,
      title: "Download the Apps",
      description: "You can download the BookingKoala app for iPhone and Android.",
      links: [
        { href: "https://apps.apple.com/us/app/bookingkoala-for-providers/id1446530801", text: "iPhone App" },
        { href: "https://play.google.com/store/apps/details?id=com.bookingkoala.bkforproviders&hl=en_US&pli=1", text: "Android App" }
      ],
      actionText: "Done"
    },
    {
      number: 4,
      title: "Save the Concierge Line to your phone",
      description: "Save this phone number: 727-228-6075 for bookings and logistics.",
      actionText: "Acknowledged"
    },
    {
      number: 5,
      title: "Essential Job Equipment Checklist",
      description: "This equipment that we have tallied from our team members that came up with to do the cleaning job",
      list: [
        "All Purpose Cleaner (Zep Industrial Purple Cleaner and Degreaser Concentrate or Simple Green Pro HD Purple Concentrated Cleaner & Degreaser - Heavy Duty recommended)",
        "Microfiber Towels",
        "Glass Cleaner",
        "Gloves (Nitrile)",
        "Tall Kitchen Trash Bags",
        "Toilet Bowl Cleaner/Toilet Bowl Brush",
        "Floor Cleaner",
        "Non-Scratch Sponge",
        "Soft Bristle Brush",
        "Grout Brush",
        "Scouring Stick (Pumice Stone) (For toilets)",
        "Steel Wool Grade 0000",
        "Vacuum (needed) <span class='text-red-600 font-bold'>Mandatory</span>",
        "Mop (needed) <span class='text-red-600 font-bold'>Mandatory</span>",
        "Paper Towels",
        "Hard Water Remover- CLR (CLR Calcium, Lime & Rust Remover) (Needed for Heavy Cleanes Only*)",
        "Duster",
        "Mold and Mildew Cleaner",
        "Squeegee and a Scraper",
        "Small Four Step Ladder"
      ],
      actionText: "I'm aware of the list",
      customClass: "expanded-width" // Add this custom class
    },
    {
      number: 6,
      title: "Set up Booking Koala - Critical",
      description: "Very Important please take a time to add a photo to your profile and set your schedule availability in the calendar, and confirm with the concierge that everything is good to go. Booking Koala allows you to update your schedule at any time, so please make sure to set it up so that if you receive 48 hours' notice for a job assignment, you'll be available to go out.",
      actionText: "Acknowledged"
    },
    {
      number: 7,
      title: "Please Make sure to arrive at least 10 minutes early to the job site. Ask questions to your team leader. You are not required to bring everything during your first few appointments, but please ensure you have sufficient cleaning products to perform the job.",
      actionText: "I'm aware"
    },
    {
      number: 8,
      title: "Coordinate with your team leader",
      description: "Once this has been completed concierge will reach out to you via the concierge number listed in box #4.",
      actionText: "Acknowledged"
    }
  ];

  return (
    <>
      <Header />
      <div className="min-h-screen bg-gray-100 p-8">
        <div className="max-w-4xl mx-auto bg-white p-6 rounded-lg shadow-lg">
          <div className="mb-6">
            <h2 className="text-4xl font-bold mb-4 text-brandBlue">Affordable Cleaning Today</h2>
            <div className="bg-blue-100 p-4 rounded-md">
              <h3 className="text-2xl font-semibold mb-2 text-blue-800">Welcome to ECHO from Affordable Cleaning Today</h3>
              <p className="text-blue-700">
                A system designed to help you onboard quicker. We have a suggested list of equipment, 
                but feel free to use whatever you find best to do the job efficiently.
              </p>
            </div>
          </div>
          <div className="mb-6">
            <div className="progress-bar" style={{ width: `${progress}%` }}></div>
          </div>
          {error && (
            <div className="bg-red-100 text-red-700 p-4 rounded-md mb-4">
              {error}
            </div>
          )}
          {tasks.map(task => (
            <div key={task.number} className={`mb-6 checklist-item ${task.customClass || ''}`}>
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
                <div className="flex-grow">
                  <h3 className="text-2xl font-semibold mb-2 task-title">{task.number + 1}. {task.title}</h3>
                  {task.description && <p className="mb-4">{task.description}</p>}
                  {task.list && (
                    <ul className="list-disc">
                      {task.list.map((item, index) => (
                        <li key={index} dangerouslySetInnerHTML={{ __html: item }} />
                      ))}
                    </ul>
                  )}
                  {task.link && (
                    <a
                      href={task.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 underline mb-4 block"
                    >
                      {task.linkText}
                    </a>
                  )}
                  {task.links && task.links.map((link, index) => (
                    <a
                      key={index}
                      href={link.href}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 underline mb-4 block"
                    >
                      {link.text}
                    </a>
                  ))}
                  <hr className="my-4 border-t border-blue-500" />
                  <div className="flex justify-center">
                    <label className="inline-flex items-center flex-col text-center">
                      <input
                        type="checkbox"
                        disabled={completedTasks[task.number]?.completed}
                        checked={completedTasks[task.number]?.completed || false}
                        onClick={() => !completedTasks[task.number]?.completed && handleCheck(task.number)}
                        className="form-checkbox h-6 w-6 text-blue-600 rounded-full border-blue-600"
                      />
                      <span className="ml-2 mt-2">{task.actionText}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <button onClick={() => { localStorage.removeItem('currentUser'); window.location.href = '/login'; }} className="bg-red-500 text-white py-2 px-4 rounded-md mt-6">
            Logout
          </button>
        </div>
      </div>
    </>
  );
};

export default OnboardingPage;
