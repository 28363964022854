import React from 'react';
import actLogo from '../assets/actlogo.png';

const Header = () => {
  return (
    <header className="bg-brandBlue p-4 flex items-center justify-center">
      <img src={actLogo} alt="ACT Logo" className="w-12 h-12" />
    </header>
  );
};

export default Header;
